import React, { useState} from "react";
// import {getFeeds, followUser, addPostToStream} from './Stream';

const AppContext = React.createContext({
  authenticated: false,
  user: {},
  loading: true
});

const AppProvider = ({ children }) => {
  const [contextState, setContextState] = useState({
    authenticated: false,
    user: {},
    loading: true
  });

  return (
    <AppContext.Provider
      value={{
        state: contextState,
        setContextState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
