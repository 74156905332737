import React from "react";
import App from "next/app";
import Head from "next/head";
import * as Sentry from "@sentry/browser";
import TagManager from "react-gtm-module";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../src/theme";

import { AppProvider } from "../src/utils/AppContext";
import { NextSeo } from "next-seo";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

Sentry.init({
  dsn: "https://29007d1d6e56449bb803acc103d12d9e@o416825.ingest.sentry.io/5313513",
});

const tagManagerArgs = {
  gtmId: "GTM-PWGRNRF",
  auth: process.env.GTM_AUTH || "LE9QH5gaVe6zw_iXvqpidA",
  preview: process.env.GTM_PREVIEW || "env-8",
};

export default class MyApp extends App {
  componentDidMount() {
    // Initialize GTM
    TagManager.initialize(tagManagerArgs);
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <React.Fragment>
        {pageProps.upload && (
          <NextSeo
            title={pageProps.upload.title}
            description={pageProps.upload.description}
            canonical="https://betterthan.vegas"
            openGraph={{
              type: "video.other",
              url: `https://betterthan.vegas/video/${pageProps.upload.id}`,
              title: pageProps.upload.title,
              description: pageProps.upload.description,
              images: [
                {
                  url: pageProps.upload.thumbnailUrl,
                  width: 1280,
                  height: 720,
                  alt: pageProps.upload.description,
                },
              ],
              videos: [
                {
                  url: `https://betterthan.vegas/video/${pageProps.upload.id}`,
                  secure_url: `https://betterthan.vegas/video/${pageProps.upload.id}`,
                  type: "text/html",
                  width: 1280,
                  height: 720,
                },
              ],
              site_name: "Better Than Vegas",
            }}
            twitter={{
              handle: "@betterthanvegastesthandle",
              site: "@betterthanvegastesthandle",
              cardType: "summary_large_image",
            }}
          />
        )}

        <AppProvider>
          <Head>
            <title>Better than Vegas</title>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
            <meta
              name="description"
              content="Better Than Vegas is a video sharing platform for sports lovers and gamblers looking to gain an edge on the competition."
            />
          </Head>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Component {...pageProps} />
          </ThemeProvider>
        </AppProvider>
      </React.Fragment>
    );
  }
}
