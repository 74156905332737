import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
const theme = createMuiTheme({
  overrides: {
    MuiListItemIcon: {
      root: { color: "#000" }
    },
    MuiTableSortLabel: {
      root: {        
        '&.MuiTableSortLabel-active': {
          color: '#fff',
          '& .MuiTableSortLabel-icon': {
            color: '#fff !important'
          }
        }
      }
    },
    MuiTable: {
      root: {
        '& caption a': {
          color: '#F44336'
        }
      }
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#1A2A36",
        color: '#fff'
      },
      body: {
        'th&': {
          fontWeight: 'bold'
        }
      },
      footer: {
        fontSize: "0.875rem",
        backgroundColor: 'rgba(26, 42, 54, 0.65)',
        color: '#fff',
        'th&': {
          fontWeight: 'bold'
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#1A2A36"
    },
    secondary: {
      main: "#F44336"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    },
  }
});

export default theme;
